import { AlertTitle, Snackbar } from '@mui/material'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'

const intlMessages = defineMessages({
    npsSurveyAlertTitle: {
        defaultMessage: 'Thank you for your response.',
        description: 'The title for the NPS survey alert'
    },
    npsSurveyAlertText: {
        defaultMessage: 'Your opinion drives everything we do at Thrive.',
        description: 'The text for the NPS survey alert'
    }
})

export type Props = {
    isOpen: boolean
    onClose: () => void
}

function NpsSurveySnackbar({ isOpen, onClose }: Props) {
    const intl = useIntl()

    return (
        <Snackbar
            data-testid="nps-success-snackbar"
            open={isOpen}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <CoreAlert severity="success" onClose={onClose}>
                <AlertTitle>
                    <CoreTypography variant={'h5'}>
                        {intl.formatMessage(intlMessages.npsSurveyAlertTitle)}
                    </CoreTypography>
                </AlertTitle>
                <CoreTypography variant={'body1'}>
                    {intl.formatMessage(intlMessages.npsSurveyAlertText)}
                </CoreTypography>
            </CoreAlert>
        </Snackbar>
    )
}

export default NpsSurveySnackbar
