import { useCallback } from 'react'
import { getPlatform } from '@thriveglobal/thrive-web-core'
import { useRecordNpsResultMutation } from '../graphql/generated/autogenerated'
import { Avo, defaultTrackingProps } from '@thriveglobal/thrive-web-tracking'

type Props = {
    userId: string
    locale: string
    companyId: string
    sessionId: string
    setIsDialogOpen: (value: boolean) => void
    setIsNpsSurveySnackbarOpen: (value: boolean) => void
}

const useNpsSurveyFormSubmission = ({
    userId,
    locale,
    companyId,
    sessionId,
    setIsDialogOpen,
    setIsNpsSurveySnackbarOpen
}: Props) => {
    const [recordNpsResultMutation, { loading }] = useRecordNpsResultMutation()
    const openSnackbar = () => {
        setIsNpsSurveySnackbarOpen(true)
    }

    const submitNpsSurvey = useCallback(
        (score: number, comment: string) => {
            const platform = getPlatform()
            const locationPath = window.location.pathname
            recordNpsResultMutation({
                variables: {
                    completionDate: new Date().toISOString(),
                    locationPath,
                    npsScore: score,
                    platform,
                    comment,
                    userId,
                    userLanguage: locale,
                    sessionId
                }
            }).then(() => {
                Avo.surveySubmitted({
                    ...defaultTrackingProps,
                    activityType: 'nps_submitted',
                    npsScore: score,
                    openAnswer: comment,
                    featureType: 'nps',
                    surveyLocation: locationPath,
                    assessmentId: null,
                    assessmentName: null,
                    assessmentItemId: null,
                    dciCount: null,
                    journey: null,
                    journeyDay: null,
                    journeyId: null,
                    journeyLevel: null,
                    isOnboarding: false,
                    responseText: null,
                    responseValue: null,
                    assessmentAttemptId: null,
                    assessmentVariant: null,
                    firstJourney: null,
                    tabName: null
                })
                setIsDialogOpen(false)
                score && openSnackbar()
            })
        },
        [
            userId,
            locale,
            recordNpsResultMutation,
            setIsDialogOpen,
            setIsNpsSurveySnackbarOpen,
            sessionId,
            companyId
        ]
    )

    return { submitNpsSurvey, loading }
}

export default useNpsSurveyFormSubmission
