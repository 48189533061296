import React, { PropsWithChildren } from 'react'
import { Card } from '@mui/material'
import { LeafCloseButton } from '@thriveglobal/thrive-web-leafkit'

interface BaseBannerProps {
    onClose?: () => void
}

const BaseBanner: React.FC<PropsWithChildren<BaseBannerProps>> = ({
    onClose,
    children
}) => (
    <Card
        sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            minWidth: 275,
            position: 'relative',
            '.MuiCardMedia-root': {
                padding: 2,
                display: 'flex'
            }
        }}
    >
        {onClose && (
            <LeafCloseButton
                color="primary"
                variant="text"
                onClick={onClose}
                sx={{
                    alignSelf: { xs: 'flex-end', md: 'flex-start' },
                    order: { xs: 'unset', md: 3 },
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    p: 2
                }}
            />
        )}
        {children}
    </Card>
)

export default BaseBanner
