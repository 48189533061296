// import { Avo, defaultTrackingProps } from '@thriveglobal/thrive-web-tracking'

export interface ActionBanner {
    type: 'BANNER'
    callToActionText: string
    callToActionUrl: string
    header: string
    body: string
    page: string
}

export interface ImageBanner {
    type: 'IMAGE_BANNER'
    page: string
    image: {
        url: string
        alternativeText: string
    }
}

export type Banner = ActionBanner | ImageBanner

export const isActionBanner = (o: any): o is ActionBanner =>
    o.type === 'BANNER' &&
    typeof o.callToActionText === 'string' &&
    typeof o.callToActionUrl === 'string' &&
    typeof o.page === 'string' &&
    typeof o.header === 'string' &&
    typeof o.body === 'string'

export const isImageBanner = (o: any): o is ImageBanner =>
    o.type === 'IMAGE_BANNER' &&
    o.image &&
    typeof o.image.url === 'string' &&
    typeof o.image.alternativeText === 'string' &&
    typeof o.page === 'string'

export const isValidBanner = (o: any): o is Banner => {
    switch (o.type) {
        case 'BANNER':
            return isActionBanner(o)
        case 'IMAGE_BANNER':
            return isImageBanner(o)
        default:
            return false
    }
}

type BannerStackProps = { banners: Banner[] }
type BannerStackReturn = {
    topBanner: Banner
    allBanners: Banner[]
}

export const useBannerStack = ({
    banners
}: BannerStackProps): BannerStackReturn => {
    if (!banners?.length) return { topBanner: null, allBanners: [] }

    const validBanners = banners
        .filter(isValidBanner)
        .filter(({ page }) =>
            window.location.pathname.toLowerCase().includes(page.toLowerCase())
        )

    return {
        topBanner: validBanners[0],
        allBanners: validBanners
    }
}
