import React from 'react'
import { CardMedia } from '@mui/material'
import BaseBanner from '../BaseBanner'

const BANNER_HEIGHT = '175'

interface BannerProps {
    src: string
    alt: string
}

export const ImageBanner: React.FC<BannerProps> = ({ alt, src, ...props }) => {
    const { ...cardMediaProps } = props

    return (
        <BaseBanner>
            <CardMedia
                component="img"
                height={BANNER_HEIGHT}
                src={src}
                alt={alt}
                sx={{ objectFit: 'cover' }}
                {...cardMediaProps}
            />
        </BaseBanner>
    )
}
