import { UserNpsResult } from '../graphql/generated/autogenerated'

/**
 * Get the latest NPS survey from a list of NPS results
 * @param {UserNpsResult[]} npsResults
 * @returns {UserNpsResult}
 */
export const getLatestNpsSurvey = (
    npsResults: UserNpsResult[]
): UserNpsResult => {
    return npsResults.reduce(
        (prev, current) =>
            new Date(prev.completionDate || 0) >
            new Date(current.completionDate || 0)
                ? prev
                : current,
        npsResults[0]
    )
}
