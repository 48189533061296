import { Suspense, lazy, memo, useMemo } from 'react'
//import { Avo, defaultTrackingProps } from '@thriveglobal/thrive-web-tracking'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import {
    useBannerStack,
    isActionBanner,
    isImageBanner,
    Banner
} from '../../../../../hooks/useBannerDisplay'
import { ImageBanner } from '../ImageBanner'
import { ActionBanner } from '../ActionBanner'
import { Button, Skeleton, Stack } from '@mui/material'
import {
    useCrossAppNavigation,
    useBanners
} from '@thriveglobal/thrive-web-core'

const NavigationButton = lazy(() =>
    import('@thriveglobal/thrive-web-ms-teams-app').then((module) => ({
        default: module.NavigationButton
    }))
)

export const FeaturedBanner = () => {
    const navigate = useCrossAppNavigation()
    const { topBanner } = useBannerStack({ banners: useBanners() as Banner[] })

    if (!topBanner) return <></>

    const trackCTAClick = () => {
        // Avo.promptSelected({
        //     ...defaultTrackingProps,
        //     contentId: "",
        //     contentFormatType,
        //     contentSource,
        //     contentSubtype,
        //     contentTitle,
        //     contentType,
        //     contentUrl,
        //     resetId,
        //     resetName,
        //     isOnboarding,
        //     onboardingAttemptNumber,
        //     userId_,
        //     displayText,
        //     featureType: 'journeys',
        //     activityType: 'company_banner_selected'
        // })
    }

    if (isActionBanner(topBanner))
        return (
            <ActionBanner
                action={
                    <Suspense
                        fallback={
                            <Skeleton
                                variant="rounded"
                                height={44}
                                width={113}
                            />
                        }
                    >
                        <NavigationButton
                            variant="outlined"
                            size="medium"
                            onNavigate={trackCTAClick}
                            url={topBanner.callToActionUrl}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {topBanner.callToActionText}
                            </CoreTypography>
                        </NavigationButton>
                    </Suspense>
                }
                message={
                    <Stack>
                        <CoreTypography variant="overline">
                            {topBanner.header}
                        </CoreTypography>

                        <CoreTypography
                            variant="body1"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {topBanner.body}
                        </CoreTypography>
                    </Stack>
                }
            />
        )
    else if (isImageBanner(topBanner))
        return (
            <ImageBanner
                src={topBanner.image.url}
                alt={topBanner.image.alternativeText}
            />
        )
    else return <></>
}
