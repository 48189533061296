import React from 'react'
import { CardActions, CardContent } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import BaseBanner from '../BaseBanner'

interface BannerProps {
    message: string | React.ReactElement
    action?: React.ReactElement
    onClose?: () => void
}

export const ActionBanner: React.FC<BannerProps> = ({
    message,
    action,
    onClose,
    ...props
}) => {
    const CardMessage = () => {
        if (typeof message === 'string') {
            return (
                <CoreTypography variant="h5" component="h2">
                    {message}
                </CoreTypography>
            )
        } else {
            return message
        }
    }

    return (
        <BaseBanner onClose={onClose}>
            <CardContent>
                <CardMessage />
            </CardContent>
            <CardActions>{action}</CardActions>
            {/* add tracking to the onClick
              Avo.promptSelected({
                ...defaultTrackingProps,
                contentId: contentId,
                contentFormatType,
                contentSource,
                contentSubtype,
                contentTitle,
                contentType,
                contentUrl,
                featureType: 'journeys',
                activityType: 'company_banner_selected'
            }) */}
        </BaseBanner>
    )
}

export default ActionBanner
