import {
    useGetNpsResultQuery,
    UserNpsResult
} from '../graphql/generated/autogenerated'
import { getLatestNpsSurvey } from '../utils/getLatestNpsSurvey'
import { getDiffBetweenDates } from '../utils/getDiffBetweenDates'
import { MAX_NPS_DAYS } from '../constants'

const useNpsSurveyDisplay = ({ userId, npsLastServed }) => {
    const queryOptions = useGetNpsResultQuery({
        variables: {
            getNpsResultUserId2: userId
        }
    })

    let shouldShowSurvey = false

    const diffBetweenLastServed = getDiffBetweenDates(
        new Date(),
        new Date(npsLastServed || 0)
    )
    if (!queryOptions.loading) {
        if (
            queryOptions.data?.getNPSResult &&
            !!queryOptions.data?.getNPSResult?.length
        ) {
            const npsResults = queryOptions.data.getNPSResult as UserNpsResult[]
            const latestDate = getLatestNpsSurvey(npsResults)
            const diffDays = getDiffBetweenDates(
                new Date(),
                new Date(latestDate.completionDate || 0)
            )

            shouldShowSurvey =
                diffDays > MAX_NPS_DAYS && diffBetweenLastServed > MAX_NPS_DAYS
        } else {
            if (npsLastServed) {
                shouldShowSurvey = diffBetweenLastServed > MAX_NPS_DAYS
            } else {
                shouldShowSurvey = true
            }
        }
    }

    return { shouldShowSurvey }
}

export default useNpsSurveyDisplay
