import {
    useAppSelector,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import { getDiffBetweenDates } from '../../../../utils/getDiffBetweenDates'
import { MIN_NPS_DAYS } from '../../../../constants'

export const withVisibilityControl = (Component) => (props) => {
    const hasNpsSurveyEnabled = useIsFeatureEnabled(
        'isNpsSurveyEnabled',
        false,
        true
    )
    const hasNpsSurveyTestingEnabled = useIsFeatureEnabled(
        'isNpsSurveyTestingEnabled',
        false,
        true
    )

    const {
        userId,
        locale,
        thriveTosAcceptedServerTime,
        companyId,
        npsLastServed
    } = useAppSelector((state) => ({
        userId: state.user?.userId,
        locale: state.settings.locale,
        thriveTosAcceptedServerTime: state.user?.thriveTosAcceptedServerTime,
        companyId: state.user.companyId,
        npsLastServed: state.npsSurvey.npsLastServed
    }))

    const diff = getDiffBetweenDates(
        new Date(),
        new Date(thriveTosAcceptedServerTime || Date.now())
    )

    const shouldShowComponent =
        hasNpsSurveyTestingEnabled ||
        (hasNpsSurveyEnabled && diff > MIN_NPS_DAYS)

    return shouldShowComponent ? (
        <Component
            userId={userId}
            locale={locale}
            companyId={companyId}
            npsLastServed={npsLastServed}
            hasNpsSurveyTestingEnabled={hasNpsSurveyTestingEnabled}
            {...props}
        />
    ) : null
}
