import {
    CoreTypography,
    LeafDialog,
    LeafTextField,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    useMediaQuery
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import NpsSurveySlider from '../../elements/NpsSurveySlider/NpsSurveySlider'
import { Avo, defaultTrackingProps } from '@thriveglobal/thrive-web-tracking'

const intlMessages = defineMessages({
    npsSurveyDialogTitle: {
        id: 'npssurvey.dialog.title',
        defaultMessage: 'Please take a moment to fill out a brief survey',
        description: 'Title for the NPS survey dialog'
    },
    npsSurveyDialogSubtitle: {
        id: 'npssurvey.dialog.subtitle',
        defaultMessage:
            'How likely are you to recommend Thrive to a coworker or friend?',
        description: 'Subtitle for the NPS survey dialog'
    },
    npsSurveyDialogTextfieldLabel: {
        id: 'npssurvey.dialog.textfield.label',
        defaultMessage: 'Additional comments',
        description: 'Label for the textfield in the NPS survey dialog'
    },
    npsSurveyDialogSubmitButton: {
        id: 'npssurvey.dialog.submit.button',
        defaultMessage: 'Submit',
        description: 'Submit button for the NPS survey dialog'
    },
    npsSurveyDialogSubmitButtonAriaLabel: {
        id: 'npssurvey.dialog.submit.button.aria.label',
        defaultMessage: 'Submit NPS survey',
        description: 'Submit button aria label for the NPS survey dialog'
    }
})

type Props = {
    userId
    companyId
    isOpen: boolean
    onClose: () => void
    submitNpsSurvey: (score: number, comment: string) => void
    loading: boolean
}

function NpsSurveyDialog({
    userId,
    companyId,
    isOpen,
    onClose,
    submitNpsSurvey,
    loading
}: Props) {
    const intl = useIntl()
    const theme = useTheme()

    const [score, setScore] = useState(50)
    const [comment, setComment] = useState('')

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
    const locationPath = window.location.pathname

    useEffect(() => {
        Avo.surveyServed({
            ...defaultTrackingProps,
            featureType: 'nps',
            activityType: 'nps_served',
            surveyLocation: locationPath,
            isOnboarding: false,
            assessmentId: null,
            assessmentName: null,
            assessmentAttemptId: null,
            assessmentVariant: null,
            userId_: null
        })
    }, [userId, companyId, locationPath])

    return (
        <LeafDialog
            open={isOpen}
            onClose={onClose}
            dialogTitle={
                <CoreTypography variant="h3" color="text.primary">
                    {intl.formatMessage(intlMessages.npsSurveyDialogTitle)}
                </CoreTypography>
            }
            sx={{
                [theme.breakpoints.up('md')]: {
                    '& .MuiDialog-paper': {
                        minWidth: '800px'
                    }
                }
            }}
        >
            <DialogContent
                sx={{
                    overflow: 'visible'
                }}
            >
                <Box
                    sx={{
                        pt: theme.spacing(2)
                    }}
                >
                    <CoreTypography
                        variant="h5"
                        color="text.primary"
                        sx={{
                            pb: theme.spacing(4)
                        }}
                    >
                        {intl.formatMessage(
                            intlMessages.npsSurveyDialogSubtitle
                        )}
                    </CoreTypography>
                    <NpsSurveySlider score={score} setScore={setScore} />
                    <LeafTextField
                        id="npssurvey-dialog-textfield"
                        color="primary"
                        label={intl.formatMessage(
                            intlMessages.npsSurveyDialogTextfieldLabel
                        )}
                        variant="outlined"
                        disabled={loading}
                        fullWidth
                        onChange={(event) => setComment(event.target.value)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    aria-label={intl.formatMessage(
                        intlMessages.npsSurveyDialogSubmitButtonAriaLabel
                    )}
                    color="primary"
                    onClick={() => submitNpsSurvey(score, comment)}
                    variant="contained"
                    fullWidth={isMediumScreen}
                    disabled={loading}
                >
                    <CoreTypography customVariant={'buttonNormal'}>
                        {intl.formatMessage(
                            intlMessages.npsSurveyDialogSubmitButton
                        )}
                    </CoreTypography>
                </Button>
            </DialogActions>
        </LeafDialog>
    )
}

export default NpsSurveyDialog
