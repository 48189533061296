import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { Box, Slider, useMediaQuery } from '@mui/material'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'

export type Props = {
    score: number
    setScore: (score: number) => void
}

const intlMessages = defineMessages({
    npsSurveyDialogLowMark: {
        id: 'npssurvey.dialog.lowmark',
        defaultMessage: 'Not at all likely',
        description: 'Lowest mark for the NPS survey dialog'
    },
    npsSurveyDialogHighMark: {
        id: 'npssurvey.dialog.highmark',
        defaultMessage: 'Extremely',
        description: 'Highest mark for the NPS survey dialog'
    },
    npsSurveyDialogSliderAriaLabel: {
        id: 'npssurvey.dialog.sliderarialabel',
        defaultMessage: 'Score slider',
        description: 'Aria label for the NPS survey dialog slider'
    }
})

const SLIDER_STEP = 10
const NUMBER_OF_MARKS = 10

function NpsSurveySlider({ score, setScore }: Props) {
    const intl = useIntl()
    const theme = useTheme()
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box
            data-testid="nps-slider"
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pb: theme.spacing(4),
                [theme.breakpoints.down('sm')]: {
                    pb: theme.spacing(2)
                }
            }}
        >
            {!isMediumScreen && (
                <CoreTypography
                    data-testid="nps-slider-low-mark"
                    variant="body1"
                    color="text.primary"
                    sx={{
                        mt: theme.spacing(0.5)
                    }}
                >
                    {intl.formatMessage(intlMessages.npsSurveyDialogLowMark)}
                </CoreTypography>
            )}
            <Slider
                data-testid="nps-slider-element"
                aria-label={intl.formatMessage(
                    intlMessages.npsSurveyDialogSliderAriaLabel
                )}
                defaultValue={score}
                step={SLIDER_STEP}
                marks={Array.from(
                    { length: NUMBER_OF_MARKS + 1 },
                    function (_, index) {
                        return {
                            value: index * SLIDER_STEP,
                            label: index.toString()
                        }
                    }
                )}
                sx={{
                    maxWidth: '536px',
                    '& .MuiSlider-mark': {
                        backgroundColor: 'transparent'
                    },
                    '& .MuiSlider-markLabel': {
                        paddingTop: theme.spacing(1.6),
                        ['@media (pointer: coarse)']: {
                            top: theme.spacing(3.75)
                        },
                        [theme.breakpoints.down('sm')]: {
                            top: theme.spacing(2)
                        }
                    },

                    ['@media (pointer: coarse)']: {
                        padding: `${theme.spacing(1.625)} 0`
                    }
                }}
                onChange={(_event, value: number) => setScore(value)}
            />
            {!isMediumScreen && (
                <CoreTypography
                    data-testid="nps-slider-high-mark"
                    variant="body1"
                    color="text.primary"
                    sx={{
                        mt: theme.spacing(0.5)
                    }}
                >
                    {intl.formatMessage(intlMessages.npsSurveyDialogHighMark)}
                </CoreTypography>
            )}
        </Box>
    )
}

export default NpsSurveySlider
