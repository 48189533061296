import React, { useCallback, useEffect, useState } from 'react'
import NpsSurveyDialog from '../../../../components/features/NpsSurveyDialog/NpsSurveyDialog'
import NpsSurveySnackbar from '../../../../components/elements/NpsSurveySnackbar/NpsSurveySnackbar'
import useNpsSurveyFormSubmission from '../../../../hooks/useNpsFormSubmission'
import useNpsSurveyDisplay from '../../../../hooks/useNpsSurveyDisplay'

import { withVisibilityControl } from './withVisibilityControl'
import {
    setNpsLastServed,
    useAppDispatch,
    useSessionStorage
} from '@thriveglobal/thrive-web-core'

type Props = {
    userId: string
    companyId: string
    npsLastServed: number | null
    locale: string
    hasNpsSurveyTestingEnabled: boolean
}

const NpsSurvey = ({
    userId,
    locale,
    hasNpsSurveyTestingEnabled,
    companyId,
    npsLastServed
}: Props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(true)
    const [isNpsSurveySnackbarOpen, setIsNpsSurveySnackbarOpen] =
        useState(false)
    const [sessionId] = useSessionStorage('THRIVE_SEGMENT_SESSION_ID')
    const { submitNpsSurvey, loading } = useNpsSurveyFormSubmission({
        userId,
        locale,
        companyId,
        sessionId: sessionId as string,
        setIsDialogOpen,
        setIsNpsSurveySnackbarOpen
    })

    const dispatch = useAppDispatch()

    const { shouldShowSurvey } = useNpsSurveyDisplay({ userId, npsLastServed })

    const handleCloseDialog = useCallback(() => {
        submitNpsSurvey(null, null)
        setIsDialogOpen(false)
    }, [])

    const handleCloseSnackbar = useCallback(
        () => setIsNpsSurveySnackbarOpen(false),
        []
    )

    const displayNpsSurvey = hasNpsSurveyTestingEnabled || shouldShowSurvey

    useEffect(() => {
        if (!isDialogOpen) {
            dispatch(setNpsLastServed(Date.now()))
        }
    }, [isDialogOpen, dispatch])

    return displayNpsSurvey ? (
        <>
            <NpsSurveyDialog
                userId={userId}
                companyId={companyId}
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                submitNpsSurvey={submitNpsSurvey}
                loading={loading}
            />
            <NpsSurveySnackbar
                isOpen={isNpsSurveySnackbarOpen}
                onClose={handleCloseSnackbar}
            />
        </>
    ) : null
}

export default withVisibilityControl(NpsSurvey)
